/*===== Colors =====//*/
$theme-color: #001232;
$body-color: #666666;
$heading-color: #333333;

/*===== Font Family =====//*/
$body-font: 'Source Sans Pro', sans-serif;
$heading-font: 'Oswald', sans-serif;

/* Layouts*/
$sp-layout : 'only screen and (min-width: 1920px)';
$xx-layout : 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$xlg-layout : 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$xs-layout: 'only screen and (max-width: 575px)';
$xxs-layout: 'only screen and (max-width: 420px)';

@media all and (min-width: 480px) {
    .Login {
        padding: 60px 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}